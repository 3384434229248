var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "postListContainer" },
    [
      _vm.serviceJobData.length !== 0
        ? _c(
            "div",
            { ref: "postInfoContainer", staticClass: "postInfoContainer" },
            [
              _c(
                "div",
                { ref: "displayContainer", staticClass: "displayContainer" },
                _vm._l(_vm.serviceJobData, function(postInfo, index) {
                  return _c("PostInformationItem", {
                    key: postInfo.gigId + index,
                    staticClass: "postContainer",
                    attrs: {
                      postInfo: postInfo,
                      operatingMenu: _vm.operatingMenu(postInfo),
                      fromType: "search",
                      searchPageKey: _vm.searchPageKey
                    },
                    on: { resetLayout: _vm.resetLayout }
                  })
                }),
                1
              ),
              _vm.serviceJobData.length !== 0
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next",
                      "page-size": _vm.pageSize,
                      total: _vm.dataTotal
                    },
                    on: { "current-change": _vm.currentChange }
                  })
                : _vm._e()
            ],
            1
          )
        : _c("PlaceholderMap", { attrs: { placeholderType: 1 } }),
      _vm.loading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "loadingContainer"
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }