var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "informationArea" },
    [
      _c("BusinessCard", {
        staticClass: "businessCard",
        attrs: {
          businessCardDetails: _vm.businessCardInfo,
          searchPageKey: _vm.searchPageKey
        }
      }),
      _c("informationFooterArea", { attrs: { footerInfo: _vm.footerInfo } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }