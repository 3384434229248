<template>
  <div
    class="allInformation"
    ref="allInformation"
    @scroll.passive="handleScroll"
    v-loading="dataLoading"
  >
    <div
      class="searchDisplayBlock"
      v-for="(item, index) in displayData"
      :key="index"
    >
      <div
        class="blockTitle"
        v-if="
          (index === 0 && item.providerData.length !== 0) ||
            (index === 1 && item.groupData.length !== 0) ||
            (index === 2 && item.serviceJobData.length !== 0)
        "
      >
        <span
          class="iconfont_Me leftIconStyle"
          :class="item.blockIconClassName"
        ></span>
        <span
          class="iconfont_Me icon-right rightIconStyle"
          @click.stop="jumpTabContainer(index)"
          v-if="
            (index === 0 && item.providerData.length === 4) ||
              (index === 1 && item.groupData.length === 4) ||
              (index === 2 && item.serviceJobData.length === 4)
          "
        ></span>
      </div>
      <div
        class="personnelArea resultArea"
        v-if="index == 0 && item.providerData.length !== 0"
      >
        <PersonalInformationItem
          class="smallAreaItem"
          v-for="providerInfo in item.providerData"
          :key="providerInfo.providerId"
          :providerInfo="providerInfo"
          :searchPageKey="searchPageKey"
        ></PersonalInformationItem>
      </div>
      <div
        class="groupArea resultArea"
        v-if="index == 1 && item.groupData.length !== 0"
      >
        <GroupInformationItem
          class="smallAreaItem"
          v-for="groupInfo in item.groupData"
          :key="groupInfo.pkId"
          :groupInfo="groupInfo"
          :searchPageKey="searchPageKey"
        ></GroupInformationItem>
      </div>
      <div
        class="postArea resultArea"
        v-if="index == 2 && item.serviceJobData.length !== 0"
      >
        <div class="displayContainer" ref="displayContainer">
          <PostInformationItem
            v-for="postInfo in item.serviceJobData"
            :key="postInfo.gigId"
            :postInfo="postInfo"
            class="postContainer"
            :operatingMenu="operatingMenu(postInfo)"
            @resetLayout="resetLayout"
            :fromType="'search'"
            :searchPageKey="searchPageKey"
          ></PostInformationItem>
        </div>
      </div>
    </div>
    <PlaceholderMap
      :placeholderType="1"
      v-if="
        displayData[0].providerData.length === 0 &&
          displayData[1].groupData.length === 0 &&
          displayData[2].serviceJobData.length === 0
      "
    ></PlaceholderMap>
  </div>
</template>
<script>
import PersonalInformationItem from "../../../components/homeDisplayComponents/personalInformationItem";
import GroupInformationItem from "../../../components/homeDisplayComponents/groupInformationItem";
import PostInformationItem from "../../../components/homeDisplayComponents/postInformationItem";
import { getRecommendedData } from "@/api/newVersion/searchPage";
import { deletePost } from "@/api/newVersion/personalCenter";
import PlaceholderMap from "../../../components/placeholderMap";
import {
  getFavoritesCount,
  startCollection,
  startUnfavorite,
} from "@/api/newVersion/interfaceForFavorites";
import Masonry from "masonry-layout";
export default {
  components: {
    PersonalInformationItem,
    GroupInformationItem,
    PostInformationItem,
    PlaceholderMap,
  },
  data() {
    return {
      // 数据加载loading
      dataLoading: false,
      // 滚动方向监听
      scrollTop: 0,
      // 瀑布流对象
      msnry: null,
      displayData: [
        // 个人信息模块数据
        {
          blockIconClassName: "icon-user-fill",
          // 个人信息数据
          providerData: [],
        },
        // 群组信息模块数据
        {
          blockIconClassName: "icon-group-fill",
          // 群组信息数据
          groupData: [],
        },
        // 帖子信息模块数据
        {
          blockIconClassName: "icon-Post-fill",
          // 帖子信息数据
          serviceJobData: [],
        },
      ],
    };
  },
  updated() {
    // 监听刷新让帖子动态布局
    if (this.msnry) {
      this.msnry.layout();
    }
  },
  computed: {
    searchPageKey() {
      return this.$store.state.searchPage.searchPageKey;
    },
  },
  methods: {
    // 滚动监听
    handleScroll() {
      // 页面滚动距顶部距离
      let scrollTop = this.$refs.allInformation.scrollTop;
      let scroll = scrollTop - this.scrollTop;
      this.scrollTop = scrollTop;
      if (scroll < 0) {
        this.$emit("handleScroll", true);
      } else {
        this.$emit("handleScroll", false);
      }
    },
    // 获取收藏夹总数
    getFavoritesCountData() {
      return new Promise(async (resolve, reject) => {
        let result = await getFavoritesCount();
        if (result.code == 200) {
          resolve(result.data.data);
        } else {
          reject(result.message);
        }
      });
    },
    // 气泡弹窗操作菜单
    operatingMenu(itemInfo) {
      let operatingMenu = [
        {
          imageSrc: itemInfo.bookMarkStatus
            ? require("../../../../assets/images/favorites/favorited.png")
            : require("../../../../assets/images/favorites/notFavorited.png"),
          imageWidth: 14,
          imageHeight: 13,
          operatingFn: this.collectionAction,
          tooltipContent: this.$t("collection"),
        },
      ];
      if (
        this.$store.getters.userInfo.manager_role == 1 ||
        this.isSelfPublish(itemInfo)
      ) {
        operatingMenu.push({
          iconClassName: "icon-delet",
          operatingFn: () => {
            return this.deleteConfirm(itemInfo);
          },
          tooltipContent: this.$t("delete"),
        });
      }
      // 不是管理员，并且不是自己的帖子,显示投诉图标
      if (
        this.$store.getters.userInfo.manager_role != 1 &&
        !this.isSelfPublish(itemInfo)
      ) {
        operatingMenu.push({
          imageSrc: require("../../../../assets/images/complaint.png"),
          imageWidth: 14,
          imageHeight: 13,
          operatingFn: () => {
            return this.complaintConfirm(itemInfo);
          },
          tooltipContent: this.$t("complaint"),
        });
      }
      return operatingMenu;
    },
    deleteConfirm(itemInfo) {
      // 自己删除自己的帖子
      if (this.isSelfPublish(itemInfo)) {
        this.confirm(null, null, "el-icon-delete", () => {
          return this.deletePost(itemInfo);
        });
      } else {
        this.$store.commit("setDeletePostId", itemInfo.gigId);
        this.$store.commit("setDeleteDialogVisible", true);
      }
    },
    complaintConfirm(itemInfo) {
      this.$store.commit("setComplaintType", 1);
      this.$store.commit("setComplaintId", itemInfo.gigId);
      this.$store.commit("setComplaintDialogVisible", true);
    },
    async deletePost(itemInfo) {
      let params = {
        helogigId: itemInfo.gigId,
        userId: this.$store.getters.userInfo.id,
      };
      let result = await deletePost(params);
      if (result) {
        this.$store.commit("setReloadPage", false);
        this.$nextTick((e) => {
          this.$store.commit("setReloadPage", true);
        });
      }
    },
    // 判断是否是自己发布的帖子
    isSelfPublish(itemInfo) {
      return this.$store.getters.userInfo.id == itemInfo.providerId;
    },
    // 收藏操作
    async collectionAction(postInfo) {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      // 如果已经是收藏状态的话，则执行取消收藏操作
      if (postInfo.bookMarkStatus) {
        await this.submitUnfavoriteInfo(postInfo);
      } else {
        // 这里需要获取一下是否有收藏夹，如果没有的话就直接收藏，如果有的话则先打开收藏弹窗
        this.getFavoritesCountData()
          .then(async (result) => {
            let handlerPostInfo = _.cloneDeep(postInfo);
            handlerPostInfo.favoritesType = "post";
            if (result > 0) {
              // 将当前帖子基本信息存入收藏状态管理器
              this.$store.commit(
                "setNeedToOperatObjectInformation",
                handlerPostInfo
              );
              // 这一步是展示选择收藏夹组件
              this.$store.commit("setNeedToShowInDialog", "selectFavoritesBox");
              // 打开收藏操作弹窗
              this.$store.commit("setFavoritesDialogStatus", true);
            } else {
              // 如果没有收藏夹，则直接收藏
              await this.favoriteCurrentPost(handlerPostInfo);
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error,
            });
          });
      }
    },
    // 取消收藏
    async submitUnfavoriteInfo(postInfo) {
      let result = await startUnfavorite(postInfo.gigId);
      if (result.code == 200) {
        // 这里存储用于刷新列表帖子收藏状态
        this.$store.commit("setHasBeenOperatedObject", postInfo);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 收藏当前帖子
    favoriteCurrentPost(postInfo) {
      return new Promise(async (resolve, reject) => {
        let dataId = postInfo.gigId;
        let params = { type: "1" };
        let result = await startCollection(dataId, params);
        if (result.code == 200) {
          // 这里存储用于刷新列表帖子收藏状态
          this.$store.commit("setHasBeenOperatedObject", postInfo);
          // ===================================================================================================
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      });
    },
    // 修改帖子的收藏状态
    modifPostFavoriteStatus(alreadyEditedItem) {
      this.displayData[2].serviceJobData = this.displayData[2].serviceJobData.map(
        (item) => {
          if (item.gigId === alreadyEditedItem.gigId) {
            item.bookMarkStatus = !item.bookMarkStatus;
          }
          return item;
        }
      );
      this.$forceUpdate();
    },
    // 点击跳转到对应的tab页面
    jumpTabContainer(index) {
      this.$emit("tabActive", index + 1);
      this.$emit("handleScroll", true);
    },
    // 开始搜索数据
    startGetSearchData() {
      // 每次请求完都重置默认数据
      Object.assign(this.$data, this.$options.data.call(this));
      this.$nextTick(async () => {
        await this.startRecommendedData();
      });
    },
    // 按键滚动
    keyupScroll(keyCode) {
      try {
        if (keyCode === 38) {
          this.$refs.allInformation.scrollTo(
            0,
            this.$refs.allInformation.scrollTop - 100
          );
        } else if (keyCode === 40) {
          this.$refs.allInformation.scrollTo(
            0,
            this.$refs.allInformation.scrollTop + 100
          );
        }
      } catch (error) {}
    },
    // 开始搜索默认的各四条推荐数据
    startRecommendedData() {
      return new Promise(async (resolve, reject) => {
        let params = {
          page: 1,
          pageSize: 4,
          countries: this.$store.state.searchPage.searchCountry,
          searchTerms: this.searchPageKey,
          categoryIds: this.$store.state.searchPage.searchCategoryId,
        };
        this.dataLoading = true;
        let result = await getRecommendedData(params);
        if (result.code == 200) {
          this.displayData[0].providerData = result.data.data.personalVos;
          this.displayData[1].groupData = result.data.data.groupVos;
          this.displayData[2].serviceJobData = result.data.data.helogigVos;
          this.$forceUpdate();
          this.$nextTick(() => {
            // 数据变动时重置瀑布流
            try {
              let grid = this.$refs.displayContainer[0];
              this.msnry = new Masonry(grid, {
                itemSelector: ".postContainer",
                columnWidth: 572,
                gutter: 16,
                transitionDuration: "0s",
              });
            } catch (e) {}
            this.dataLoading = false;
          });
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      });
    },
    // 主动调用重排列表
    resetLayout() {
      this.$nextTick(() => {
        if (this.msnry) {
          this.msnry.layout();
        }
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.allInformation {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .searchDisplayBlock {
    width: 100%;
    height: auto;
    overflow: hidden;

    .blockTitle {
      height: 50px;
      margin-top: 10px;
      padding: 0 15px;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;

      .leftIconStyle {
        font-size: 22px;
        color: #333333;
      }

      .rightIconStyle {
        font-size: 20px;
        color: #333333;
        cursor: pointer;
      }
    }

    .resultArea {
      width: 100%;
      min-height: 100px;
      overflow: hidden;
    }

    .personnelArea, .groupArea {
      display: flex;
      flex-flow: row;
      align-items: flex-start;
      justify-content: flex-start;

      .smallAreaItem {
        width: 278px;
        min-height: 0;
        background: #ffffff;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .displayContainer {
    .postContainer {
      width: 572px;
      min-height: 0px;
      margin-bottom: 16px;
      background: #FFFFFF;
    }
  }
}
</style>
