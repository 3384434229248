<template>
  <div class="groupListContainer">
    <div
      class="groupInfoContainer"
      v-loading="loading"
      ref="groupInfoContainer"
      v-if="groupData.length !== 0"
      :style="{
        'padding-bottom': searchPageKey != '' ? '' : '40px',
      }"
    >
      <GroupInformationItem
        class="groupInfoAreaItem"
        v-for="item in groupData"
        :key="item.pkId"
        :groupInfo="item"
        :searchPageKey="searchPageKey"
      ></GroupInformationItem>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="dataTotal"
        v-if="groupData.length !== 0"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <PlaceholderMap :placeholderType="1" v-else></PlaceholderMap>
  </div>
</template>
<script>
import GroupInformationItem from "../../../components/homeDisplayComponents/groupInformationItem";
import PlaceholderMap from "../../../components/placeholderMap";
import { getGroupList } from "@/api/newVersion/searchPage";
export default {
  components: {
    GroupInformationItem,
    PlaceholderMap,
  },
  data() {
    return {
      // 数据加载loading
      loading: false,
      // 群组信息数据
      groupData: [],
      // 每一页展示的数量
      pageSize: 20,
      // 数据总量
      dataTotal: 0,
      // 当前页数
      currentPage: 1,
      // 滚动方向监听
      scrollTop: 0,
    };
  },
  computed: {
    // 输入的关键词
    searchPageKey() {
      return this.$store.state.searchPage.searchPageKey;
    },
  },
  methods: {
    // 按键滚动
    keyupScroll(keyCode) {
      try {
        if (keyCode === 38) {
          this.$refs.groupInfoContainer.scrollTo(
            0,
            this.$refs.groupInfoContainer.scrollTop - 100
          );
        } else if (keyCode === 40) {
          this.$refs.groupInfoContainer.scrollTo(
            0,
            this.$refs.groupInfoContainer.scrollTop + 100
          );
        }
      } catch (error) {}
    },
    // 滚动监听
    handleScroll() {
      // 页面滚动距顶部距离
      let scrollTop = this.$refs.groupInfoContainer.scrollTop;
      let scroll = scrollTop - this.scrollTop;
      this.scrollTop = scrollTop;
      if (scroll < 0) {
        this.$emit("handleScroll", true);
      } else {
        this.$emit("handleScroll", false);
      }
    },
    // 开始搜索数据
    async startGetSearchData() {
      this.currentPage = 1;
      this.groupData = [];
      await this.sendRequestForData();
    },
    // 页码发生改变了
    async currentChange(e) {
      this.currentPage = e;
      this.$refs.groupInfoContainer.scrollTo(0, 0);
      this.$nextTick(() => {
        this.$emit("handleScroll", true);
      });
      await this.sendRequestForData();
    },
    // 搜索数据
    async sendRequestForData() {
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        accessType: this.$store.state.searchPage.accessType,
        searchTerms: this.searchPageKey,
        countries: this.$store.state.searchPage.searchCountry,
        categoryIds: this.$store.state.searchPage.searchCategoryId,
      };
      this.$nextTick(async () => {
        this.loading = true;
        let result = await getGroupList(params);
        this.loading = false;
        if (result.code == 200) {
          this.groupData = result.data.data;
          this.dataTotal = Number(result.data.total);
          if (this.groupData.length !== 0) {
            this.$forceUpdate();
            this.$nextTick(() => {
              if (this.$refs.groupInfoContainer) {
                this.$refs.groupInfoContainer.addEventListener(
                  "scroll",
                  this.handleScroll,
                  true
                );
              }
            });
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.groupListContainer
  width 100%;
  height 100%;
  overflow hidden;
  position relative;
  .loadingContainer
    width 100%;
    height 100%;
    position absolute;
    top 0;
    left 0;
  .groupInfoContainer
    width 100%;
    height 100%;
    overflow-x hidden;
    overflow-y auto;
    scrollbar-width: none;
    &::-webkit-scrollbar
      width 0px;
    .groupInfoAreaItem
      width 278px;
      min-height 290px;
      background #ffffff;
      display inline-block;
      margin-top 20px;
      margin-right 16px;
      &:nth-child(4n)
        margin-right 0;
</style>
<style lang="stylus">
.groupInfoContainer
  .el-pagination
    .btn-prev
      height 40px !important;
      border-radius 50% !important;
    .btn-next
      height 40px !important;
      border-radius 50% !important;
    .el-pager
      li
        height 40px !important;
        line-height: 40px !important;
        border-radius 50% !important;
</style>
