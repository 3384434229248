var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "groupListContainer" },
    [
      _vm.groupData.length !== 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "groupInfoContainer",
              staticClass: "groupInfoContainer",
              style: {
                "padding-bottom": _vm.searchPageKey != "" ? "" : "40px"
              }
            },
            [
              _vm._l(_vm.groupData, function(item) {
                return _c("GroupInformationItem", {
                  key: item.pkId,
                  staticClass: "groupInfoAreaItem",
                  attrs: { groupInfo: item, searchPageKey: _vm.searchPageKey }
                })
              }),
              _vm.groupData.length !== 0
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next",
                      "page-size": _vm.pageSize,
                      total: _vm.dataTotal
                    },
                    on: { "current-change": _vm.currentChange }
                  })
                : _vm._e()
            ],
            2
          )
        : _c("PlaceholderMap", { attrs: { placeholderType: 1 } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }