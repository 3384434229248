<template>
  <div class="searchContent">
    <div
      class="tabbarHeader"
      :style="{
        height: tabbarHeaderFlag ? '60px' : '0',
        opacity: tabbarHeaderFlag ? '1' : '0',
      }"
    >
      <div
        class="tabbarBox"
        :style="{ width: isInterestedSearch === '1' ? '256px' : '512px' }"
      >
        <Tabbar
          :tabIndex="searchTabIndex"
          :tabbars="showTabbars"
          @tabActive="tabActive"
        ></Tabbar>
      </div>
      <span
        class="iconStyle iconfont_Me icon-replay"
        @click.stop="randomSearch"
        v-if="showRefresh"
      ></span>
    </div>
    <div
      class="tabbarContent"
      :style="{ paddingTop: tabbarHeaderFlag ? '60px' : '0' }"
    >
      <el-carousel
        height="100%"
        :initial-index="searchTabIndex"
        indicator-position="none"
        arrow="never"
        :autoplay="false"
        :loop="false"
        ref="tabbarContent"
      >
        <el-carousel-item v-for="item in showTabbars" :key="item.className">
          <div
            :is="item.componentName"
            :ref="item.componentName"
            @handleScroll="handleScroll"
            @tabActive="tabActive"
          ></div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import Tabbar from "../../components/tabbar";
import AllInformation from "./contentComponents/allInformation";
import PersonnelInformation from "./contentComponents/personnelInformation";
import GroupInformation from "./contentComponents/groupInformation";
import PostInformation from "./contentComponents/postInformation";
export default {
  components: {
    Tabbar,
    AllInformation,
    PersonnelInformation,
    GroupInformation,
    PostInformation,
  },
  data() {
    return {
      // 头部切换菜单是否展示
      tabbarHeaderFlag: true,
    };
  },
  computed: {
    // 是否是感兴趣的搜索页面，是的话就展示对应的选项
    isInterestedSearch() {
      return this.$route.params.isInterestedSearch;
    },
    showRefresh() {
      return (
        ((this.isInterestedSearch === "1" && this.searchTabIndex == 0) ||
          (this.isInterestedSearch !== "1" && this.searchTabIndex == 1)) &&
        this.searchPageKey == ""
      );
    },
    showTabbars() {
      if (this.isInterestedSearch === "1") {
        return [
          {
            className: "icon-user-fill",
            componentName: "PersonnelInformation",
          },
          {
            className: "icon-group-fill",
            componentName: "GroupInformation",
          },
        ];
      } else {
        return [
          {
            className: "icon-all-fill",
            componentName: "AllInformation",
          },
          {
            className: "icon-user-fill",
            componentName: "PersonnelInformation",
          },
          {
            className: "icon-group-fill",
            componentName: "GroupInformation",
          },
          {
            className: "icon-Post-fill",
            componentName: "PostInformation",
          },
        ];
      }
    },
    searchTabIndex() {
      return this.$store.state.searchPage.searchTabIndex;
    },
    searchPageKey() {
      return this.$store.state.searchPage.searchPageKey;
    },
  },
  watch: {
    // 监听切换的tabbar，用于每次切换tabbar的时候触发每个tabbar对应的搜索事件
    searchTabIndex: {
      handler(val, old) {
        if (val != old) {
          this.triggerTheTabbarSearchEvent();
        }
      },
      deep: true,
    },
    // 监听列表数据中的收藏状态变化(主要是为了监听在收藏弹窗中操作了数据过后改变帖子列表收藏状态)
    "$store.state.favorites.hasBeenOperatedObject": {
      handler(val, old) {
        if (val && val !== old && this.$route.name === "newSearch") {
          this.$refs[
            this.showTabbars[this.searchTabIndex].componentName
          ][0].modifPostFavoriteStatus(val);
          this.$store.commit("setHasBeenOperatedObject", null);
          this.$forceUpdate();
        }
      },
      deep: true,
    },
  },
  mounted() {
    // 监听键盘按钮
    document.addEventListener("keyup", this.keyupChange);
    this.triggerTheTabbarSearchEvent();
  },
  destroyed() {
    document.removeEventListener("keyup", this.keyupChange);
  },
  methods: {
    // 滚动方向监听
    handleScroll(flag) {
      this.tabbarHeaderFlag = flag;
    },
    // 切换tab事件
    tabActive(tabIndex) {
      // 将选中的tab下标存入状态管理器
      this.$store.commit("setSearchTabIndex", tabIndex);
      this.$refs.tabbarContent.setActiveItem(this.searchTabIndex);
    },
    // 按键下载
    keyupChange(e) {
      if (this.$store.state.imStore.IMWindowSize === "mini") {
        this.$refs[
          this.showTabbars[this.searchTabIndex].componentName
        ][0].keyupScroll(e.keyCode);
      }
    },
    // 随机刷新
    randomSearch() {
      this.$store.commit("setResetAllSearchCriteria", true);
      // 每次进入其他页面进来都要重置一下搜索条件
      this.$store.commit("setGender", null);
      this.$store.commit("setSearchCountry", "");
      this.$store.commit("setSearchCategoryId", "");
      this.$store.commit("setAccessType", null);
      this.$nextTick(() => {
        this.triggerTheTabbarSearchEvent();
      });
    },
    // 由父组件触发的搜索事件
    triggerTheTabbarSearchEvent() {
      this.$refs[
        this.showTabbars[this.searchTabIndex].componentName
      ][0].startGetSearchData();
    },
  },
};
</script>
<style lang="stylus" scoped>
.searchContent
  width 100%;
  flex 1;
  min-height 0;
  overflow hidden;
  display flex;
  background #F7F7F7;
  flex-flow column;
  align-items center;
  justify-content flex-start;
  position relative;
  .tabbarHeader
    width 1160px;
    flex-shrink 0;
    transition height .3s, opacity .3s;
    overflow hidden;
    background #FFFFFF;
    display flex;
    flex-flow column-reverse;
    position absolute;
    top 0;
    .tabbarBox
      height 40px;
    .iconStyle
      font-size: 24px;
      position: absolute;
      bottom: 12px;
      right: 24px;
      color: #8F8F8F;
      cursor: pointer;
  .tabbarContent
    flex 1;
    width 1160px;
    min-height 0;
    overflow hidden;
    box-sizing border-box;
</style>
<style lang="stylus">
.tabbarContent
  .el-carousel
    height 100%;
</style>
