<template>
  <div class="postListContainer">
    <div
      class="postInfoContainer"
      ref="postInfoContainer"
      v-if="serviceJobData.length !== 0"
    >
      <div class="displayContainer" ref="displayContainer">
        <PostInformationItem
          v-for="(postInfo, index) in serviceJobData"
          :key="postInfo.gigId + index"
          :postInfo="postInfo"
          class="postContainer"
          :operatingMenu="operatingMenu(postInfo)"
          @resetLayout="resetLayout"
          :fromType="'search'"
          :searchPageKey="searchPageKey"
        ></PostInformationItem>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="dataTotal"
        v-if="serviceJobData.length !== 0"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <PlaceholderMap :placeholderType="1" v-else></PlaceholderMap>
    <div class="loadingContainer" v-if="loading" v-loading="loading"></div>
  </div>
</template>
<script>
import PostInformationItem from "../../../components/homeDisplayComponents/postInformationItem";
import { getPostList } from "@/api/newVersion/searchPage";
import { deletePost, shieldPost } from "@/api/newVersion/personalCenter";
import PlaceholderMap from "../../../components/placeholderMap";
import Masonry from "masonry-layout";
import {
  getFavoritesCount,
  startCollection,
  startUnfavorite,
} from "@/api/newVersion/interfaceForFavorites";
export default {
  components: {
    PostInformationItem,
    PlaceholderMap,
  },
  data() {
    return {
      // 数据加载loading
      loading: false,
      // 帖子信息数据
      serviceJobData: [],
      // 每一页展示的数量
      pageSize: 20,
      // 当前页数
      currentPage: 1,
      // 数据总量
      dataTotal: 0,
      // 瀑布流对象
      msnry: null,
      // 滚动方向监听
      scrollTop: 0,
    };
  },
  watch: {
    "$store.state.searchPage.searchTabIndex": {
      handler(val, old) {
        // 说明从帖子页面跳转走了
        if (old === 3 && val !== old) {
          this.$nextTick(() => {
            Object.assign(this.$data, this.$options.data.call(this));
          });
        }
      },
      deep: true,
    },
  },
  updated() {
    // 数据变动时重置瀑布流
    if (this.serviceJobData.length !== 0) {
      this.$nextTick(() => {
        let grid = this.$refs.displayContainer;
        try {
          this.msnry = new Masonry(grid, {
            itemSelector: ".postContainer",
            columnWidth: 572,
            gutter: 16,
            transitionDuration: "0s",
          });
        } catch (error) {}
      });
    }
  },
  computed: {
    // 输入的关键词
    searchPageKey() {
      return this.$store.state.searchPage.searchPageKey;
    },
  },
  methods: {
    // 获取收藏夹总数
    getFavoritesCountData() {
      return new Promise(async (resolve, reject) => {
        let result = await getFavoritesCount();
        if (result.code == 200) {
          resolve(result.data.data);
        } else {
          reject(result.message);
        }
      });
    },
    // 气泡弹窗操作菜单
    operatingMenu(itemInfo) {
      let operatingMenu = [
        {
          imageSrc: itemInfo.bookMarkStatus
            ? require("../../../../assets/images/favorites/favorited.png")
            : require("../../../../assets/images/favorites/notFavorited.png"),
          imageWidth: 14,
          imageHeight: 13,
          operatingFn: this.collectionAction,
          tooltipContent: this.$t("collection"),
        },
      ];
      if (
        this.$store.getters.userInfo.manager_role == 1 ||
        this.isSelfPublish(itemInfo)
      ) {
        operatingMenu.push({
          iconClassName: "icon-delet",
          operatingFn: () => {
            return this.deleteConfirm(itemInfo);
          },
          tooltipContent: this.$t("delete"),
        });
      }
      // 不是管理员，并且不是自己的帖子,显示投诉图标
      if (
        this.$store.getters.userInfo.manager_role != 1 &&
        !this.isSelfPublish(itemInfo)
      ) {
        operatingMenu.push(
          {
            iconClassName: "iconfont_Me icon-a-zu5510",
            operatingFn: () => {
              return this.complaintConfirm(itemInfo);
            },
            tooltipContent: this.$t("complaint"),
          },
          {
            iconClassName: "iconfont_Me icon-a-zu9817",
            operatingFn: () => {
              return this.shieldConfirm(itemInfo);
            },
            tooltipContent: this.$t("shield"),
          }
        );
      }
      return operatingMenu;
    },
    // 判断是否是自己发布的帖子
    isSelfPublish(itemInfo) {
      return this.$store.getters.userInfo.id == itemInfo.providerId;
    },
    complaintConfirm(itemInfo) {
      this.$store.commit("setComplaintType", 1);
      this.$store.commit("setComplaintId", itemInfo.gigId);
      this.$store.commit("setComplaintDialogVisible", true);
    },
    //屏蔽操作
    shieldConfirm(itemInfo) {
      this.confirm(null, this.$t("blockThisPost"), null, () => {
        this.shieldPost(itemInfo);
      });
    },
    async shieldPost(itemInfo) {
      let result = await shieldPost(itemInfo.gigId);
      if (result.code == 200) {
        if (result) {
          this.$store.commit("setReloadPage", false);
          this.$nextTick((e) => {
            this.$store.commit("setReloadPage", true);
          });
        }
        this.$message({
          message: "success",
          type: "success",
        });
      }
    },
    // 收藏操作
    async collectionAction(postInfo) {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      // 如果已经是收藏状态的话，则执行取消收藏操作
      if (postInfo.bookMarkStatus) {
        await this.submitUnfavoriteInfo(postInfo);
      } else {
        // 这里需要获取一下是否有收藏夹，如果没有的话就直接收藏，如果有的话则先打开收藏弹窗
        this.getFavoritesCountData()
          .then(async (result) => {
            let handlerPostInfo = _.cloneDeep(postInfo);
            handlerPostInfo.favoritesType = "post";
            if (result > 0) {
              // 将当前帖子基本信息存入收藏状态管理器
              this.$store.commit(
                "setNeedToOperatObjectInformation",
                handlerPostInfo
              );
              // 这一步是展示选择收藏夹组件
              this.$store.commit("setNeedToShowInDialog", "selectFavoritesBox");
              // 打开收藏操作弹窗
              this.$store.commit("setFavoritesDialogStatus", true);
            } else {
              // 如果没有收藏夹，则直接收藏
              await this.favoriteCurrentPost(handlerPostInfo);
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error,
            });
          });
      }
    },
    // 取消收藏
    async submitUnfavoriteInfo(postInfo) {
      let result = await startUnfavorite(postInfo.gigId);
      if (result.code == 200) {
        // 这里存储用于刷新列表帖子收藏状态
        this.$store.commit("setHasBeenOperatedObject", postInfo);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 收藏当前帖子
    favoriteCurrentPost(postInfo) {
      return new Promise(async (resolve, reject) => {
        let dataId = postInfo.gigId;
        let params = { type: "1" };
        let result = await startCollection(dataId, params);
        if (result.code == 200) {
          // 这里存储用于刷新列表帖子收藏状态
          this.$store.commit("setHasBeenOperatedObject", postInfo);
          // ===================================================================================================
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      });
    },
    // 修改帖子的收藏状态
    modifPostFavoriteStatus(alreadyEditedItem) {
      this.serviceJobData = this.serviceJobData.map((item) => {
        if (item.gigId === alreadyEditedItem.gigId) {
          item.bookMarkStatus = !item.bookMarkStatus;
        }
        return item;
      });
      this.$forceUpdate();
    },
    deleteConfirm(itemInfo) {
      // 自己删除自己的帖子
      if (this.isSelfPublish(itemInfo)) {
        this.confirm(null, null, "el-icon-delete", () => {
          return this.deletePost(itemInfo);
        });
      } else {
        this.$store.commit("setDeleteType", "post");
        this.$store.commit("setDeletePostId", itemInfo.gigId);
        this.$store.commit("setDeleteDialogVisible", true);
      }
    },
    async deletePost(itemInfo) {
      let params = {
        helogigId: itemInfo.gigId,
        userId: this.$store.getters.userInfo.id,
      };
      let result = await deletePost(params);
      if (result) {
        this.$store.commit("setReloadPage", false);
        this.$nextTick((e) => {
          this.$store.commit("setReloadPage", true);
        });
      }
    },
    // 按键滚动
    keyupScroll(keyCode) {
      try {
        if (keyCode === 38) {
          this.$refs.postInfoContainer.scrollTo(
            0,
            this.$refs.postInfoContainer.scrollTop - 100
          );
        } else if (keyCode === 40) {
          this.$refs.postInfoContainer.scrollTo(
            0,
            this.$refs.postInfoContainer.scrollTop + 100
          );
        }
      } catch (error) {}
    },
    // 滚动监听
    handleScroll() {
      // 页面滚动距顶部距离
      let scrollTop = this.$refs.postInfoContainer.scrollTop;
      let scroll = scrollTop - this.scrollTop;
      this.scrollTop = scrollTop;
      if (scroll < 0) {
        this.$emit("handleScroll", true);
      } else {
        this.$emit("handleScroll", false);
      }
    },
    // 主动调用重排列表
    resetLayout() {
      if (this.msnry) {
        this.$nextTick(() => {
          this.msnry.layout();
        });
      }
    },
    // 开始搜索数据
    startGetSearchData() {
      this.serviceJobData = [];
      // 将当前页面重置为1
      this.currentPage = 1;
      let params = {
        categoryIds: this.$store.state.searchPage.searchCategoryId,
        pageSize: this.pageSize,
        page: this.currentPage,
        countries: this.$store.state.searchPage.searchCountry,
        searchTerms: this.searchPageKey,
      };
      this.$nextTick(async () => {
        this.loading = true;
        await this.sendRequest(params);
        this.loading = false;
      });
    },
    // 加载指定页面数据
    async currentChange(e) {
      this.currentPage = e;
      this.$refs.postInfoContainer.scrollTo(0, 0);
      this.$nextTick(() => {
        this.$emit("handleScroll", true);
      });
      let params = {
        categoryIds: this.$store.state.searchPage.searchCategoryId,
        pageSize: this.pageSize,
        page: this.currentPage,
        countries: this.$store.state.searchPage.searchCountry,
        searchTerms: this.searchPageKey,
      };
      this.loading = true;
      await this.sendRequest(params);
      this.loading = false;
    },
    // 开始加载数据
    sendRequest(params) {
      return new Promise(async (resolve, reject) => {
        let result = await getPostList(params);
        if (result.code == 200) {
          this.dataTotal = Number(result.data.total);
          this.serviceJobData = result.data.data;
          this.$nextTick(() => {
            if (this.$refs.postInfoContainer) {
              this.$refs.postInfoContainer.scrollTo(0, 0);
              if (this.serviceJobData.length !== 0) {
                this.$forceUpdate();
                this.$nextTick(() => {
                  this.$refs.postInfoContainer.addEventListener(
                    "scroll",
                    this.handleScroll,
                    true
                  );
                });
              }
            }
          });
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        this.$nextTick(() => {
          resolve("success");
        });
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.postListContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .loadingContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .postInfoContainer {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
    }

    .postContainer {
      width: 572px;
      min-height: 0px;
      margin-bottom: 16px;
      background: #FFFFFF;
    }
  }
}
</style>
<style lang="stylus">
.postListContainer {
  .el-pagination {
    .btn-prev {
      height: 40px !important;
      border-radius: 50% !important;
    }

    .btn-next {
      height: 40px !important;
      border-radius: 50% !important;
    }

    .el-pager {
      li {
        height: 40px !important;
        line-height: 40px !important;
        border-radius: 50% !important;
      }
    }
  }
}

.el-message-box__title {
  font-size: 14px;
}
</style>
