var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personalListContainer" },
    [
      _vm.providerData.length !== 0
        ? _c(
            "div",
            {
              ref: "personalInfoContainer",
              staticClass: "personalInfoContainer",
              style: {
                "padding-bottom": _vm.searchPageKey != "" ? "" : "40px"
              }
            },
            [
              _vm._l(_vm.providerData, function(item) {
                return _c("PersonalInformationItem", {
                  key: item.provider_id,
                  staticClass: "personalInfoAreaItem",
                  attrs: {
                    providerInfo: item,
                    searchPageKey: _vm.searchPageKey
                  }
                })
              }),
              _vm.showPagination
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next",
                      "page-size": _vm.pageSize,
                      total: _vm.dataTotal
                    },
                    on: { "current-change": _vm.currentChange }
                  })
                : _vm._e()
            ],
            2
          )
        : _c("PlaceholderMap", { attrs: { placeholderType: 1 } }),
      _vm.loading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "loadingContainer"
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }