var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.dataLoading,
          expression: "dataLoading"
        }
      ],
      ref: "allInformation",
      staticClass: "allInformation",
      on: {
        "&scroll": function($event) {
          return _vm.handleScroll.apply(null, arguments)
        }
      }
    },
    [
      _vm._l(_vm.displayData, function(item, index) {
        return _c("div", { key: index, staticClass: "searchDisplayBlock" }, [
          (index === 0 && item.providerData.length !== 0) ||
          (index === 1 && item.groupData.length !== 0) ||
          (index === 2 && item.serviceJobData.length !== 0)
            ? _c("div", { staticClass: "blockTitle" }, [
                _c("span", {
                  staticClass: "iconfont_Me leftIconStyle",
                  class: item.blockIconClassName
                }),
                (index === 0 && item.providerData.length === 4) ||
                (index === 1 && item.groupData.length === 4) ||
                (index === 2 && item.serviceJobData.length === 4)
                  ? _c("span", {
                      staticClass: "iconfont_Me icon-right rightIconStyle",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.jumpTabContainer(index)
                        }
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e(),
          index == 0 && item.providerData.length !== 0
            ? _c(
                "div",
                { staticClass: "personnelArea resultArea" },
                _vm._l(item.providerData, function(providerInfo) {
                  return _c("PersonalInformationItem", {
                    key: providerInfo.providerId,
                    staticClass: "smallAreaItem",
                    attrs: {
                      providerInfo: providerInfo,
                      searchPageKey: _vm.searchPageKey
                    }
                  })
                }),
                1
              )
            : _vm._e(),
          index == 1 && item.groupData.length !== 0
            ? _c(
                "div",
                { staticClass: "groupArea resultArea" },
                _vm._l(item.groupData, function(groupInfo) {
                  return _c("GroupInformationItem", {
                    key: groupInfo.pkId,
                    staticClass: "smallAreaItem",
                    attrs: {
                      groupInfo: groupInfo,
                      searchPageKey: _vm.searchPageKey
                    }
                  })
                }),
                1
              )
            : _vm._e(),
          index == 2 && item.serviceJobData.length !== 0
            ? _c("div", { staticClass: "postArea resultArea" }, [
                _c(
                  "div",
                  {
                    ref: "displayContainer",
                    refInFor: true,
                    staticClass: "displayContainer"
                  },
                  _vm._l(item.serviceJobData, function(postInfo) {
                    return _c("PostInformationItem", {
                      key: postInfo.gigId,
                      staticClass: "postContainer",
                      attrs: {
                        postInfo: postInfo,
                        operatingMenu: _vm.operatingMenu(postInfo),
                        fromType: "search",
                        searchPageKey: _vm.searchPageKey
                      },
                      on: { resetLayout: _vm.resetLayout }
                    })
                  }),
                  1
                )
              ])
            : _vm._e()
        ])
      }),
      _vm.displayData[0].providerData.length === 0 &&
      _vm.displayData[1].groupData.length === 0 &&
      _vm.displayData[2].serviceJobData.length === 0
        ? _c("PlaceholderMap", { attrs: { placeholderType: 1 } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }