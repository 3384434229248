<template>
  <div class="personalListContainer">
    <div
      class="personalInfoContainer"
      ref="personalInfoContainer"
      v-if="providerData.length !== 0"
      :style="{
        'padding-bottom': searchPageKey != '' ? '' : '40px',
      }"
    >
      <PersonalInformationItem
        class="personalInfoAreaItem"
        v-for="item in providerData"
        :key="item.provider_id"
        :providerInfo="item"
        :searchPageKey="searchPageKey"
      ></PersonalInformationItem>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="dataTotal"
        v-if="showPagination"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <PlaceholderMap :placeholderType="1" v-else></PlaceholderMap>
    <div class="loadingContainer" v-if="loading" v-loading="loading"></div>
  </div>
</template>
<script>
import PersonalInformationItem from "../../../components/homeDisplayComponents/personalInformationItem";
import PlaceholderMap from "../../../components/placeholderMap";
import {
  getPersonList,
  getRandomPersonList,
} from "@/api/newVersion/searchPage";
export default {
  components: {
    PersonalInformationItem,
    PlaceholderMap,
  },
  data() {
    return {
      // 数据加载loading
      loading: false,
      // 个人信息数据
      providerData: [],
      // 每一页展示的数量
      pageSize: 20,
      // 数据总量
      dataTotal: 0,
      // 当前页数
      currentPage: 1,
      // 滚动方向监听
      scrollTop: 0,
    };
  },
  computed: {
    showPagination() {
      return this.providerData.length !== 0 && this.withSearchCriteria;
    },
    withSearchCriteria() {
      return (
        this.searchPageKey != "" ||
        this.$store.state.searchPage.searchCountry !== "" ||
        this.$store.state.searchPage.searchCategoryId !== "" ||
        this.$store.state.searchPage.gender !== null ||
        this.$store.state.searchPage.accessType !== null
      );
    },
    // 输入的关键词
    searchPageKey() {
      return this.$store.state.searchPage.searchPageKey;
    },
  },
  methods: {
    // 按键滚动
    keyupScroll(keyCode) {
      try {
        if (keyCode === 38) {
          this.$refs.personalInfoContainer.scrollTo(
            0,
            this.$refs.personalInfoContainer.scrollTop - 100
          );
        } else if (keyCode === 40) {
          this.$refs.personalInfoContainer.scrollTo(
            0,
            this.$refs.personalInfoContainer.scrollTop + 100
          );
        }
      } catch (error) {}
    },
    // 滚动监听
    handleScroll() {
      // 页面滚动距顶部距离
      let scrollTop = this.$refs.personalInfoContainer.scrollTop;
      let scroll = scrollTop - this.scrollTop;
      this.scrollTop = scrollTop;
      if (scroll < 0) {
        this.$emit("handleScroll", true);
      } else {
        this.$emit("handleScroll", false);
      }
    },
    // 开始搜索数据
    async startGetSearchData() {
      this.currentPage = 1;
      this.providerData = [];
      if (this.withSearchCriteria) {
        await this.sendRequestForData();
      } else {
        await this.getRandomLis();
      }
    },
    async getRandomLis() {
      this.loading = true;
      const result = await getRandomPersonList();
      this.loading = false;
      if (result.code == 200) {
        this.providerData = result.data.data;
        if (this.providerData.length !== 0) {
          this.$forceUpdate();
          this.$nextTick(() => {
            if (this.$refs.personalInfoContainer) {
              this.$refs.personalInfoContainer.addEventListener(
                "scroll",
                this.handleScroll,
                true
              );
            }
          });
        }
      }
    },
    // 页码发生改变了
    async currentChange(e) {
      this.currentPage = e;
      this.$refs.personalInfoContainer.scrollTo(0, 0);
      this.$nextTick(() => {
        this.$emit("handleScroll", true);
      });
      await this.sendRequestForData();
    },
    // 搜索数据
    async sendRequestForData() {
      let params = {
        categoryIds: this.$store.state.searchPage.searchCategoryId,
        pageSize: this.pageSize,
        page: this.currentPage,
        gender: this.$store.state.searchPage.gender,
        countries: this.$store.state.searchPage.searchCountry,
        searchTerms: this.searchPageKey,
      };
      this.$nextTick(async () => {
        this.loading = true;
        let result = await getPersonList(params);
        this.loading = false;
        if (result.code == 200) {
          this.providerData = result.data.data;
          this.dataTotal = Number(result.data.total);
          if (this.providerData.length !== 0) {
            this.$forceUpdate();
            this.$nextTick(() => {
              if (this.$refs.personalInfoContainer) {
                this.$refs.personalInfoContainer.addEventListener(
                  "scroll",
                  this.handleScroll,
                  true
                );
              }
            });
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.personalListContainer
  width 100%;
  height 100%;
  overflow hidden;
  position relative;
  .loadingContainer
    width 100%;
    height 100%;
    position absolute;
    top 0;
    left 0;
  .personalInfoContainer
    width 100%;
    height 100%;
    overflow-x hidden;
    overflow-y auto;
    scrollbar-width: none;
    &::-webkit-scrollbar
      width 0px;
    .personalInfoAreaItem
      width 278px;
      min-height 290px;
      background #ffffff;
      display inline-block;
      margin-top 20px;
      margin-right 16px;
      &:nth-child(4n)
        margin-right 0;
</style>
<style lang="stylus">
.personalInfoContainer
  .el-pagination
    .btn-prev
      height 40px !important;
      border-radius 50% !important;
    .btn-next
      height 40px !important;
      border-radius 50% !important;
    .el-pager
      li
        height 40px !important;
        line-height: 40px !important;
        border-radius 50% !important;
</style>
