<template>
  <div class="informationArea">
    <BusinessCard
      class="businessCard"
      :businessCardDetails="businessCardInfo"
      :searchPageKey="searchPageKey"
    ></BusinessCard>
    <informationFooterArea :footerInfo="footerInfo"></informationFooterArea>
  </div>
</template>
<script>
import BusinessCard from "../businessCard";
import informationFooterArea from "../homeDisplayComponents/informationFooterArea";
export default {
  components: {
    BusinessCard,
    informationFooterArea,
  },
  props: {
    providerInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 需要高亮显示的字体
    searchPageKey: {
      type: String,
      default: "",
    },
  },
  computed: {
    // 名片信息集合
    businessCardInfo() {
      // 处理名片标签数组
      let tags = [];
      if (this.providerInfo.skills && this.providerInfo.skills !== "") {
        tags = this.providerInfo.skills.split(",");
      }
      return {
        avatar: this.providerInfo.profilePhoto,
        providerId: this.providerInfo.providerId,
        userName: this.providerInfo.name,
        gender: this.providerInfo.gender,
        countryCode: this.providerInfo.country || 0,
        iCanDo: "",
        aboutMe: this.providerInfo.aboutMe,
        tags: tags,
        vipStatus: this.providerInfo.vipStatus,
      };
    },
    // 底部信息集合
    footerInfo() {
      return {
        likeStatus: this.providerInfo.likeStatus,
        praiseCount: this.providerInfo.likeCount,
        replyCount: this.providerInfo.commentCount,
        type: 3,
        id: this.providerInfo.providerId,
        imDisplayName: this.providerInfo.name,
        imAvatar: this.providerInfo.profilePhoto,
        imIsGroup: false,
      };
    },
  },
};
</script>
<style lang="stylus" scoped>
.informationArea
  width 100%;
  box-sizing border-box;
  padding 10px 0;
  overflow hidden;
  display flex;
  flex-flow column;
  align-items center;
  justify-content flex-start;
  box-shadow: 0px 3px 6px 0px rgba(204,204,204,0.16);
  .businessCard
    cursor pointer;
    min-height 240px;
</style>
