<template>
  <div class="searchPage">
    <SearchContent ref="searchContent"></SearchContent>
  </div>
</template>
<script>
import SearchContent from "./searchContent";
export default {
  components: {
    SearchContent,
  },
  data() {
    return {};
  },
  watch: {
    // 用于监听是否需要开始搜索
    "$store.state.searchPage.needStartSearch": {
      handler(val, old) {
        if (val && val !== old) {
          this.$refs.searchContent.triggerTheTabbarSearchEvent();
          this.$store.commit("setNeedStartSearch", false);
        }
      },
      deep: true,
    },
  },
  // 监听是否是从子项详情页面过来的，如果是的话，就获取之前存储的下标，跳转到对应的下标上去
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        from.name === "user" ||
        from.name === "detail" ||
        from.name === "postContentDetails"
      ) {
        // 每次现将当前选中的下标重置
        vm.$nextTick(() => {
          vm.$store.commit("setSearchTabIndex", 0);
          vm.$refs.searchContent.tabActive(
            vm.$store.state.searchPage.indexBeforeJumpDetails
          );
        });
      } else {
        // 如果是从其他页面进来的，就将记录重置
        vm.$store.commit("setIndexBeforeJumpDetails", 0);
        // 每次进入其他页面进来都要重置一下搜索条件
        vm.$store.commit("setGender", null);
        vm.$store.commit("setSearchCountry", "");
        vm.$store.commit("setSearchCategoryId", "");
        vm.$store.commit("setAccessType", null);
        vm.$forceUpdate();
      }
    });
  },
  // 监听是否离开页面进入对应子项的详情页面，是的话就将tab下标存储起来
  beforeRouteLeave(to, from, next) {
    if (
      to.name === "user" ||
      to.name === "detail" ||
      to.name === "postContentDetails"
    ) {
      this.$store.commit(
        "setIndexBeforeJumpDetails",
        this.$store.state.searchPage.searchTabIndex
      );
    } else {
      // 如果是离开搜索页面，则将下标记录设置为空
      this.$store.commit("setIndexBeforeJumpDetails", 0);
      this.$store.commit("setSearchPageKey", "");
    }
    next();
  },
  methods: {},
};
</script>
<style lang="stylus" scoped>
.searchPage {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
}
</style>
